import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Chevron } from '@jutro/components';
import styles from './PolicyInfoHeaderComponent.module.scss';

class PolicyInfoHeaderComponent extends Component {
    /**
     * @memberof gw-components-platform-react.PolicyInfoHeaderComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.TileName - Tile Name
     * @prop {func} propTypes.onEditTile - callback to edit Tile data
     * @prop {bool} propTypes.isAccordionOpen - is accordion open
     * @prop {bool} propTypes.isEditable - is it editable
     */

    static propTypes = {
        tileName: PropTypes.string.isRequired,
        isAccordionOpen: PropTypes.bool,
        tileID: PropTypes.number,
        isSelected: PropTypes.bool,
        onEditTile: PropTypes.func
    };

    static defaultProps = {
        isAccordionOpen: false,
        tileID: undefined,
        isSelected: false,
        onEditTile: undefined
    };

    editTile = () => {
        const { onEditTile, tileID, isAccordionOpen } = this.props;
        onEditTile(tileID, isAccordionOpen);
    };

    render() {
        const {
            tileName,
            isAccordionOpen,
            isSelected
        } = this.props;
        const disableHeader = !isSelected && isAccordionOpen ? styles.disableTile : null;
        const tileNameVerified = `${tileName} verified`;

        return (
            <div className={styles.headerContainer}>
                <div className={styles.headerDetails}>
                    <div className={disableHeader}>
                        <Chevron isOpen={isAccordionOpen} className={styles.chevron} />
                        {(isSelected) ? (
                            <div className={styles.selectedHeader}>
                                <div className={styles.selectedHeaderTitle} onClick={this.editTile}>
                                    {isSelected ? (
                                        <div className={styles.checkMark}>
                                            <i className="fa fa-check" aria-hidden="true" role="img" aria-label={tileNameVerified} title={tileNameVerified} />
                                        </div>
                                    )
                                        : null
                                    }
                                    <div className={styles.headingColumn}>
                                        <span>
                                            {tileName}
                                        </span>
                                    </div>
                                </div>
                                {isSelected ? (
                                    <div className={styles.editRemoveLink}>
                                        <a onClick={this.editTile}>
                                            edit
                                        </a>
                                    </div>
                                ) : null
                                }
                            </div>
                        )
                            : (
                                <div className={styles.selectedHeader}>
                                    <div className={styles.headerTitle} onClick={this.editTile}>
                                        <div className={styles.tileHeadingColumn}>
                                            <span>
                                                {tileName}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default PolicyInfoHeaderComponent;
