import { defineMessages } from 'react-intl';

export default defineMessages({
    welcomeText: {
        id: 'quoteandbind.pa.directives.Confirmation.welcomeText',
        defaultMessage: 'Welcome to the Alfa Family!',
    },
    thankMessage: {
        id: 'quoteandbind.pa.directives.Confirmation.thankMessage',
        defaultMessage: "Thank you for trusting us with your auto insurance needs. We're here for you.",
    },
    perksText: {
        id: 'quoteandbind.pa.directives.Confirmation.perksText',
        defaultMessage: 'Perks of Being an Alfa Customer',
    },
    discountText: {
        id: 'quoteandbind.pa.directives.Confirmation.discountText',
        defaultMessage: 'Get the coverage you want with the discounts you deserve',
    },
    benefitsText: {
        id: 'quoteandbind.pa.directives.Confirmation.benefitsText',
        defaultMessage: 'Discover local and national member benefits',
    },
    homeText: {
        id: 'quoteandbind.pa.directives.Confirmation.homeText',
        defaultMessage: 'Protect more with home and life insurance bundles',
    },
    policyNumber: {
        id: 'quoteandbind.pa.directives.Confirmation.policyNumber',
        defaultMessage: 'Policy Number',
    },
    policyTerm: {
        id: 'quoteandbind.pa.directives.Confirmation.policyTerm',
        defaultMessage: 'Policy Term',
    },
    membershipNumber: {
        id: 'quoteandbind.pa.directives.Confirmation.memberShipNumber',
        defaultMessage: 'Membership Number',
    },
    youAreAllSet: {
        id: 'quoteandbind.pa.directives.Confirmation.youAreAllSet',
        defaultMessage: 'You’re all set!',
    },
    whatsNext: {
        id: 'quoteandbind.pa.directives.Confirmation.whatsNext',
        defaultMessage: 'What’s Next?',
    },
    emailText: {
        id: 'quoteandbind.pa.directives.Confirmation.emailText',
        defaultMessage: 'We sent a confirmation link to you at',
    },
    confirmationLink: {
        id: 'quoteandbind.pa.directives.Confirmation.confirmationLink',
        defaultMessage: 'We sent a confirmation link to you at '
    },
    alfaAccountActiveMsg: {
        id: 'quoteandbind.pa.directives.Confirmation.alfaAccountActiveMsg',
        defaultMessage: 'This link will activate your MyAlfa® account and will remain active for 24 hours.'
    },
    callToVerifyEmailMsg: {
        id: 'quoteandbind.pa.directives.Confirmation.callToVerifyEmailMsg',
        defaultMessage: 'If your link expires or you do not receive an email, please call 1-800-964-2532 to verify your email.'
    },
    meetYourAgent: {
        id: 'quoteandbind.pa.directives.Confirmation.meetYourAgent',
        defaultMessage: 'Meet your agent'
    },
    viewAgentBioPage: {
        id: 'quoteandbind.pa.directives.Confirmation.viewAgentBioPage',
        defaultMessage: 'View Agent Bio Page'
    },
    wooHoo: {
        id: 'quoteandbind.pa.directives.Confirmation.wooHoo',
        defaultMessage: 'Woo-Hoo!' 
    },
    relationshipDiscountMessageHomeowners: {
        id: 'quoteandbind.pa.directives.Confirmation.relationshipDiscountMessageHomeowners',
        defaultMessage: 'You selected to bundle and save, so your agent will be in touch to get your home (or renters) quote started!' 
    },
    relationshipDiscountMessageRenters: {
        id: 'quoteandbind.pa.directives.Confirmation.relationshipDiscountMessageRenters',
        defaultMessage: 'You selected to bundle and save, so your agent will be in touch to get your renters quote started!' 
    },
    bundleAndSaveAltTextHomeowners: {
        id: 'quoteandbind.pa.directives.Confirmation.bundleAndSaveAltTextHomeowners',
        defaultMessage: 'Car plus Home equals Savings' 
    },
    bundleAndSaveAltTextRenters: {
        id: 'quoteandbind.pa.directives.Confirmation.bundleAndSaveAltTextRenters',
        defaultMessage: 'Car plus Renter equals Savings' 
    }

});
