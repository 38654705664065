import { defineMessages } from 'react-intl';

export default defineMessages({
    paPolicyInfo: {
        id: 'quoteandbind.views.policyinfo.Personal Auto Policy Information',
        defaultMessage: 'Personal Auto Policy Information',
    },
    paPolicyInformation: {
        id: 'quoteandbind.pa.views.pa-policy-info.Policy Information',
        defaultMessage: 'You\'re Almost Finished!',
    },
    paPolicyTitleTooltip: {
        id: 'quoteandbind.pa.views.pa-policy-info.paPolicyTitleTooltip',
        defaultMessage: 'Some final details are required to complete your purchase',
    },
    paVinTooltip: {
        id: 'quoteandbind.pa.views.pa-policy-info.paVinTooltip',
        defaultMessage: 'A VIN is a vehicle identification number that is specific to each vehicle. A VIN is composed of 17 characters and can usually be found on the driver\'s side interior dash and under the hood front of engine block.',
    },
    paDriverBlackoutTooltip: {
        id: 'quoteandbind.pa.views.pa-policy-info.paDriverBlackoutTooltip',
        defaultMessage: 'If your driver(s) do not fit this criteria, please contact a representative at 1-800-964-2532 to complete your purchase',
    },
    paClaimDescTooltip: {
        id: 'quoteandbind.pa.views.pa-policy-info.paClaimDescTooltip',
        defaultMessage: 'Additional information is required for this claim. Please select a description to continue.',
    },
    paDriverValidLicense: {
        id: 'quoteandbind.pa.views.pa-policy-info.Valid License',
        defaultMessage: 'Valid US License',
    },
    paPolicyCaption: {
        id: 'quoteandbind.pa.views.pa-policy-info.Policy Caption',
        defaultMessage: 'If you need to add an additional named insured, you will need to contact an agent or call 1-800-964-2532.',
    },
    paDriverLicenseReason: {
        id: 'quoteandbind.pa.views.pa-policy-info.Reason',
        defaultMessage: 'Reason',
    },
    paDriverState: {
        id: 'quoteandbind.pa.views.pa-policy-info.State',
        defaultMessage: 'State',
    },
    paLease: {
        id: 'quoteandbind.pa.views.pa-policy-info.Other Financing Leasing Company',
        defaultMessage: 'Other Financing/Leasing Company',
    },
    paLeaseLookup: {
        id: 'quoteandbind.pa.views.pa-policy-info.Finance Leasing Company',
        defaultMessage: 'Finance/Leasing Company',
    },
    paSecLeaseLookup: {
        id: 'quoteandbind.pa.views.pa-policy-info.Secondary Company',
        defaultMessage: 'Secondary Company',
    },
    paDriverBlackout: {
        id: 'quoteandbind.pa.views.pa-policy-info.Driver Blackout',
        defaultMessage: 'Have any of the drivers had blackouts or seizures in the last 5 years?',
    },
    paDriverArrested: {
        id: 'quoteandbind.pa.views.pa-policy-info.Driver Arrested',
        defaultMessage: 'Have any of the drivers ever been arrested or incarcerated?',
    },
    paDriverLicense: {
        id: 'quoteandbind.pa.views.pa-policy-info.Drivers License',
        defaultMessage: 'Drivers License',
    },
    paVehicleDamageTooltip: {
        id: 'quoteandbind.pa.views.pa-policy-info.paVehicleDamageTooltip',
        defaultMessage: 'If your vehicle(s) does not fit this criteria, please contact a representative at 1-800-964-2532 to complete your purchase.',
    },
    paMailingAddress: {
        id: 'quoteandbind.pa.views.pa-policy-info.Mailing Address',
        defaultMessage: 'Mailing Address',
    },
    paPhoneError: {
        id: 'quoteandbind.pa.views.pa-policy-info.Phone Error',
        defaultMessage: 'Please enter a valid 10 digit phone number',
    },
    coverageStartDate: {
        id: 'quoteandbind.pa.views.pa-policy-info.Coverage Start Date',
        defaultMessage: 'Coverage Start Date',
    },
    withinCityLimit: {
        id: 'quoteandbind.pa.views.pa-policy-info.Within city limit',
        defaultMessage: ' within city limit?',
    },
    vehicleDamage: {
        id: 'quoteandbind.pa.views.pa-policy-info.Vehicle Damage',
        defaultMessage: 'Do you agree that the quoted vehicles: do not have existing damage, are in your possession, and are registered to you and/or your spouse?',
    },
    vehicles: {
        id: 'quoteandbind.pa.views.pa-policy-info.Vehicles',
        defaultMessage: 'Vehicles'
    },
    vin: {
        id: 'quoteandbind.pa.views.pa-policy-info.Vin',
        defaultMessage: 'VIN'
    },
    name: {
        id: 'quoteandbind.pa.views.pa-policy-info.Name',
        defaultMessage: 'Name'
    },
    phoneNumber: {
        id: 'quoteandbind.pa.views.pa-policy-info.Phone Number',
        defaultMessage: 'Phone Number'
    },
    emailAddress: {
        id: 'quoteandbind.pa.views.pa-policy-info.Email Address',
        defaultMessage: 'Email Address'
    },
    emailError: {
        id: 'quoteandbind.pa.views.pa-policy-info.Email Error',
        defaultMessage: 'Please enter a valid email address'
    },
    mvrDamage: {
        id: 'quoteandbind.pa.views.pa-policy-info.Were damages more than $899',
        defaultMessage: 'Were damages more than $899?'
    },
    mvrDamageLess: {
        id: 'quoteandbind.pa.views.pa-policy-info.Were you at fault',
        defaultMessage: 'Were you at fault?'
    },
    mvrFault: {
        id: 'quoteandbind.pa.views.pa-policy-info.Fault',
        defaultMessage: 'Description'
    },
    noDriversExist: {
        id: 'quoteandbind.pa.views.pa-policy-info.No Drivers Exist',
        defaultMessage: 'No Drivers Exist...',
    },
    primaryDriverContactDetails: {
        id: 'quoteandbind.pa.views.pa-policy-info.Primary Driver Contact Details',
        defaultMessage: 'Primary Driver Contact Details',
    },
    drivers: {
        id: 'quoteandbind.pa.views.pa-policy-info.Drivers',
        defaultMessage: 'Drivers',
    },
    displayName: {
        id: 'quoteandbind.pa.views.pa-policy-info.driver.Name',
        defaultMessage: 'Name'
    },
    licenseNumber: {
        id: 'quoteandbind.pa.views.pa-policy-info.driver.License Number',
        defaultMessage: 'License Number'
    },
    driverlicenseState: {
        id: 'quoteandbind.pa.views.pa-policy-info.driver.License State',
        defaultMessage: 'License State'
    },
    make: {
        id: 'quoteandbind.pa.views.pa-policy-info.Make',
        defaultMessage: 'Make',
    },
    noVehiclesExist: {
        id: 'quoteandbind.pa.views.pa-policy-info.No Vehicles Exist',
        defaultMessage: 'No Vehicles Exist...',
    },
    model: {
        id: 'quoteandbind.pa.views.pa-policy-info.Model',
        defaultMessage: 'Model'
    },
    year: {
        id: 'quoteandbind.pa.views.pa-policy-info.Year',
        defaultMessage: 'Year'
    },
    licensePlate: {
        id: 'quoteandbind.pa.views.pa-policy-info.License Plate',
        defaultMessage: 'License Plate'
    },
    vehicleLicenseState: {
        id: 'quoteandbind.pa.views.pa-policy-info.License State',
        defaultMessage: 'License State'
    }
});
